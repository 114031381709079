.menu{
    float: right;
    /*width: 400px;*/
    background-color: inherit !important;
    border:none;
}
.menu :global(.ant-menu-submenu-title){
    height: 30px !important;
    line-height: 30px !important;
    color: #ffffff;
}
.menu:global(.ant-menu-horizontal){
    line-height: 30px;
}
.logo{
    float: left;
    display: flex;
    align-items: center;
}
.logo img{
    width: 24px;
    height: 24px;
    margin-right: 5px;
}

.menu:global(.ant-menu.ant-menu-dark .ant-menu-item-selected) {
    background-color: hsla(0, 0%, 0%, 0.15) !important;
}
.menu:global(.ant-menu-horizontal > .ant-menu-item-selected){
    color: #ffffff;
    border:none;
    top:0;
    margin-top: 0;
    background-color: rgba(255,255,255,.2);
}
.menu:global(.ant-menu-horizontal > .ant-menu-item-active) {
    color: #ffffff;
    background-color: rgba(255,255,255,.2);
}
.menu:global(.ant-menu-horizontal > .ant-menu-submenu){
    border: none;
}

.menu:global(.ant-menu-horizontal > .ant-menu-item){
    border: none;
    color: #ffffff;

}

.menu:global(.ant-menu-horizontal > .ant-menu-item > a){
    color: #ffffff;

}


.menu:global(.ant-menu-horizontal > .ant-menu-submenu:hover){
    color: #ffffff !important;
}

.menu:global(.ant-menu-dark .ant-menu-sub){
    background-color: #ffffff !important;
}