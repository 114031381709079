
.content {
    margin: 24px 24px 0;
    /*padding: 24px;*/
    /*background: #fff;*/
    /*min-height: 280px;*/

}

.title {
    margin:  20px 16px;
    font-size: 24px;
}