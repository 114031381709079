.item-wrapper{

}

.item-wrapper > div{
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
}
.item-wrapper > div:hover{
    background-color: #cde8ff;
    cursor: pointer;
}
.item-wrapper > div > div{
    flex:1
}
.item-wrapper > div > span{
    /*display: flex;*/
    margin-left: 8px;
    color:red;
}
.active{
    background-color: #cde8ff;
}
.item-wrapper  :global(.ant-typography){
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
}