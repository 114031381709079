.login-form {
    /*box-shadow: 0 1px 20px rgba(0, 0, 0, 0.3);*/

    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -80%);
    background: rgba(255, 255, 255, 0.6);
    /*text-align: center;*/
    /*border-radius: 25px;*/
    padding: 20px;

    width: 400px;
}
.login-form h1{
    padding: 20px 0;
}
.login-form-forgot {
    float: right;
}
.login-form-button {
    width: 100%;
}

.copy-right{
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    color: #999999;
    /*text-shadow: 1px 1px 5px #000000;*/
}