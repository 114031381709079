.topicWrapper{
    border: solid 1px #cccccc;
    padding: 12px;
    background-color: #ffffff;
    border-radius: 5px;
}

.bodyWrapper {
    overflow-y: scroll;
    height: calc(100vh - 65px);
}
.bodyWrapper::-webkit-scrollbar {/*滚动条整体样式*/

    width: 20px;     /*高宽分别对应横竖滚动条的尺寸*/
}
.bodyWrapper::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 20px;
    /*box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);*/
    background   : #b0b0b0;
}
.bodyWrapper::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    /*box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);*/
    border-radius: 20px;
    background   : #ededed;
}
.canvasWrapper{
    border: solid 1px #cccccc;
    border-radius: 5px;
    position: relative;
}
.point_content_content_wrapper{
    border: solid 1px #cccccc;
    padding: 12px;
    border-radius: 5px;
    overflow: hidden;

}
.point_content_content img{
    max-width: 100%;
}
.student_header_wrapper{
    display: flex;
    align-items: center;
    padding: 10px 20px;
    border-bottom: solid 1px #dddddd;
}
.student_header_wrapper h1{
    flex:1;
    margin-bottom: 0;
}
.student_header_extr_wrapper{

}