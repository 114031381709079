.card-container p {
    margin: 0;
}
.card-container > :global(.ant-tabs-card) > :global(.ant-tabs-content) {
    /*height: 120px;*/
    margin-top: -16px;
}
.card-container > :global(.ant-tabs-card) > :global(.ant-tabs-content) {
    /*height: 120px;*/
    margin-top: -8px;
}

.card-container > :global(.ant-tabs-card) > :global(.ant-tabs-content) > :global(.ant-tabs-tabpane),
.card-container > :global(.ant-tabs-card) > :global(.ant-tabs-content) > :global(.ant-tabs-tabpane) {
    background: #fff;
    padding: 16px;
}

.card-container > :global(.ant-tabs-card) > :global(.ant-tabs-bar),
.card-container > :global(.ant-tabs-card) > :global(.ant-tabs-bar) {
    border-color: #fff;
    margin-bottom: 8px;
}

.card-container > :global(.ant-tabs-card) > :global(.ant-tabs-bar .ant-tabs-tab),
.card-container > :global(.ant-tabs-card) > :global(.ant-tabs-bar .ant-tabs-tab) {
    border-color: transparent;
    background: transparent;
}

.card-container > :global(.ant-tabs-card) > :global(.ant-tabs-bar .ant-tabs-tab-active),
.card-container > :global(.ant-tabs-card) > :global(.ant-tabs-bar .ant-tabs-tab-active) {
    border-color: #fff;
    background: #fff;
}