th.column-title{
    font-size: 12px;
    /*-webkit-transform-origin-x: 0;*/
    /*-webkit-transform: scale(0.70);*/
    /*display: block;*/
    padding: 0;
}
.table{
    /*border-top: solid 2px #000000;*/
}
.table :global(.ant-table-cell){
    padding: 8px 3px !important;

}
.table :global(.ant-table-tbody .ant-table-cell){
    font-size: 12px;
}